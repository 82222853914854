<template>
  <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-x-8 mt-4'>
    <div class='flex-shrink-0 text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4 pt-4'>
      <h1 class='font-semibold uppercase text-2xl lg:text-3xl'>
        등록비 납부 계좌 정보
      </h1>
    </div>
    <div class='flex-grow'>
      <table class='table-fixed text-sm lg:text-base w-full'>
        <tr class='uppercase text-gray-600'>
          <th class='py-2 lg:py-4 px-2 font-semibold w-40 bg-gray-100 text-center whitespace-nowrap border'>은행명</th>
          <td class='py-2 lg:py-4 px-2 text-center whitespace-nowrap border'>우리은행</td>
        </tr>
        <tr class='uppercase text-gray-600 border'>
          <th class='py-2 lg:py-4 px-2 font-semibold w-40 bg-gray-100 text-center whitespace-nowrap border'>계좌번호</th>
          <td class='py-2 lg:py-4 px-2 text-center whitespace-nowrap border'>1005-103-454402</td>
        </tr>
        <tr class='uppercase text-gray-600 border'>
          <th class='py-2 lg:py-4 px-2 font-semibold w-40 bg-gray-100 text-center whitespace-nowrap border'>예금주</th>
          <td class='py-2 lg:py-4 px-2 text-center whitespace-nowrap border'>가톨릭대학교내과학교실진흥회</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'

export default {
  name: 'MyPageRegistrationBankInfo',
  computed: {
  },
}
</script>
